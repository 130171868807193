import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import OrderDetail from './OrderDetail';
import ThankYou from './ThankYou';
import ScrollToTop from './ScrollToTop/ScrollToTop';
import GameList from './GameList/GameList';
import ProductList from './Home/index';
import RedeemCoupon from './RedeemCoupon';


export default function Routes() {
    return (
        <ScrollToTop>
            <Switch>
                <Route exact path="/:lang(ar|en)/" component={GameList} />
                <Route exact path="/:lang(ar|en)/products" component={ProductList} />
                <Route exact path="/:lang(ar|en)/order-detail" component={OrderDetail} />
                <Route exact path="/:lang(ar|en)/redeem/:game" component={RedeemCoupon} />
                <Route exact path="/:lang(ar|en)/thank-you" component={ThankYou} />
                <Route>
                    <Redirect exact to="/ar/" />
                </Route>
            </Switch>
        </ScrollToTop>
    );
}