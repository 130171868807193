import React from 'react';
import PropTypes from 'prop-types';

export default class Modal extends React.Component {

    componentDidMount() {
        if (this.props.show === true) {
            this.openModal();
        }
    }

    openModal = () => {
        window.$('#myModal').modal('show');
    }

    render() {
        return (
            <div id="myModal" className="modal" tabIndex="-1" role="dialog" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.handleModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" dangerouslySetInnerHTML={{
                            __html: this.props.body
                        }}></div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-info" data-dismiss="modal" onClick={this.props.handleModal}>{this.props.closeBtnText}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired
}