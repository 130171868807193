import React from 'react';
import './App.css';
import Routes from './Routes';
import { Router, Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import storage from './Utils/storage';
import { useTranslation } from 'react-i18next';
import utility from './Utils/utility';

const Menu = () => {
  const { t, i18n } = useTranslation();
  utility.setGlobalLanguageParams(i18n, window.location.pathname);

  return (
    <div id="navigation" className="collapse navbar-collapse">
      <ul className="navbar-nav mr-auto" style={{ flex: "auto" }}>
        <li className="nav-item"><Link to={"/" + storage.getCookieObj('lang') + "/"} className="nav-link">{t('menu.home')}</Link></li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="/en/"
            onClick={(e) => {
              e.preventDefault();
              storage.storeCookieObj('dir', "ltr", 30);
              storage.storeCookieObj('lang', "en", 30);
              window.location.replace(process.env.PUBLIC_URL + "/en/");
            }}>EN</a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="/ar/"
            onClick={(e) => {
              e.preventDefault();
              storage.storeCookieObj('dir', "rtl", 30);
              storage.storeCookieObj('lang', "ar", 30);
              window.location.replace(process.env.PUBLIC_URL + "/ar/");
            }}>AR</a>
        </li>
      </ul>
    </div>
  )
}

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div id="copyright">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mb-2 mb-lg-0 legal-info">
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://umxstudio.co/terms-condition">{t('footer.termsOfService')}</a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://umxstudio.co/privacy-policy">{t('footer.privacyPolicy')}</a>
              </li>
            </ul>
            <p className="text-center text-lg-left">{t('footer.copyright')}</p>
          </div>
          <div className="col-lg-6">
            <p className="text-center text-lg-right">
              {t('footer.poweredBy')} &nbsp;
              <a target="_blank" rel="noopener noreferrer" href="https://umxstudio.co/">UMX Studio</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default class App extends React.Component {

  componentDidMount() {
    let dir = storage.getCookieObj('dir');
    if (dir === "") dir = "rtl";
    document.getElementsByTagName('body')[0].setAttribute('dir', storage.getCookieObj('dir'));
  }

  render() {
    return (
      <Router history={createBrowserHistory({
        basename: process.env.PUBLIC_URL
      })}>
        <div className="App">
          <header className="header mb-5">
            <nav className="navbar navbar-expand-lg fixed-top">
              <div className="container"><Link to="/" className="navbar-brand home"><img src={utility.getAssetURL("/img/logo-umx.png")} alt="logo" className="d-none d-md-inline-block" width="80" /><img src={utility.getAssetURL("/img/logo-umx.png")} alt="logo" className="d-inline-block d-md-none" width="50" /></Link>
                <div className="navbar-buttons">
                  <button type="button" data-toggle="collapse" data-target="#navigation" className="btn btn-outline-secondary navbar-toggler"><span className="sr-only">Toggle navigation</span><i className="fa fa-align-justify"></i></button>
                </div>
                <Menu />
              </div>
            </nav>
          </header>

          <div className="center-content">
            <Routes />
          </div>

          <Footer />
        </div >
      </Router>
    );
  }
}