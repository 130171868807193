import React from 'react';
import { fetchProducts, fetchBanners } from '../Utils/networkUtils';
import ProductItem from './ProductItem/ProductItem';
import storage from '../Utils/storage';
import { useTranslation } from 'react-i18next';
import utility from '../Utils/utility';

const ProductList = ({ history, location, match }) => {
    let { t, i18n } = useTranslation();
    return <ProductListScreen trans={t} i18n={i18n} history={history} location={location} match={match} />
}

export default ProductList;

class ProductListScreen extends React.Component {

    state = {
        products: [],
        banners: [],
        apiCall: false
    }

    async componentDidMount() {
        let gameObj = storage.fetch('gameObj');
        if (gameObj === null) {
            let redirectUrl = utility.getLinkURL("/");
            this.props.history.push(redirectUrl);
        }

        this.setState({ apiCall: true });
        await this.fetchBannersCall();
        await this.fetchProductsCall(gameObj.id);

        let script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.src = utility.getAssetURL("/js/front.js");
        document.head.appendChild(script1);

        let script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.src = utility.getAssetURL("/js/custom.js");
        document.head.appendChild(script2);
        this.setState({ apiCall: false });
    }

    fetchProductsCall = async (gameId) => {
        let products = await fetchProducts(gameId);
        if (products !== undefined)
            this.setState({ products });
    }

    fetchBannersCall = async () => {
        let banners = await fetchBanners();
        this.setState({ banners });
    }

    selectProduct = (product) => {
        storage.store('purchaseObj', product);
        this.props.history.push(utility.getLinkURL("order-detail"));
    }

    render() {
        let lang = storage.getCookieObj('lang');
        let bannerKey = (lang === "ar") ? 'banner_ar' : 'banner';
        return (
            <div>
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {
                            this.state.banners.length > 0
                            && this.state.banners.map((banner, key) => {
                                if (banner[bannerKey] !== undefined)
                                    return <div className="swiper-slide" key={key}>
                                        <img src={banner[bannerKey]['url']} alt="" className="img-fluid" />
                                    </div>
                                return null;
                            })
                        }
                    </div>

                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                </div>

                <div id="all">
                    <div id="content">
                        <div id="hot">
                            <div className="container">
                                {
                                    this.state.apiCall &&
                                    <i className="fa fa-spinner fa-spin col-ffffff"></i>
                                }
                                <div className="product-slider owl-carousel owl-theme">
                                    {
                                        this.state.products.length > 0
                                            ? this.state.products.map((product, key) => {
                                                return <div className="item" key={key}>
                                                    <ProductItem {...product} {...this.props} onSelect={this.selectProduct} propObj={product} route="home" />
                                                </div>
                                            })
                                            : <div className="col-lg-12">
                                                <h4 className="mb-3 ta-left">No Products found</h4>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}