import storage from "./storage";

// const API_BASE_URL = 'http://167.99.82.202:1337/parse';
// const API_BASE_URL = 'http://localhost:1337/api/parse';
const API_BASE_URL = 'https://shop.umxstudio.co/api/parse';
const APP_ID = 'ktrskUeg9nSM7DWCBPMc7QTERt2arDSh9HpDqmth';

export function fetchProducts(gameId) {
    return fetch(API_BASE_URL + '/functions/productsList', {
        method: 'POST',
        body: JSON.stringify({
            "gameId": gameId,
            "lang": storage.getCookieObj("lang")
        }),
        headers: {
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': APP_ID
        }
    }).then(response => response.json()).then((data) => data.result.data);
}

export function fetchGames() {
    return fetch(API_BASE_URL + '/functions/gamesList', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': APP_ID
        }
    }).then(response => response.json()).then((data) => data.result.data);
}

export function redeemCoupon(playerId, couponCode) {
    return fetch(API_BASE_URL + '/functions/redeemCoupon', {
        method: 'POST',
        body: JSON.stringify({
            "id": playerId,
            "coupon": couponCode,
            "lang": storage.getCookieObj("lang"),
            "gameId": storage.fetch("gameObj").id
        }),
        headers: {
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': APP_ID
        }
    }).then(response => response.json()).then((data) => data.result);
}

export function verifyPlayerIdForRedemption(playerId, couponCode) {
    return fetch(API_BASE_URL + '/functions/verifyPlayerIdForRedemption', {
        method: 'POST',
        body: JSON.stringify({
            "id": playerId,
            "coupon": couponCode,
            "lang": storage.getCookieObj("lang"),
            "gameId": storage.fetch("gameObj").id
        }),
        headers: {
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': APP_ID
        }
    }).then(response => response.json()).then((data) => data.result);
}

export function verifyPlayerId(playerId, email, productId) {
    return fetch(API_BASE_URL + '/functions/xsollaPaymentInit', {
        method: 'POST',
        body: JSON.stringify({
            "gameId": storage.fetch("gameObj").id,
            "id": playerId,
            "email": email,
            "productId": productId,
            "lang": storage.getCookieObj("lang"),
            "uiVersion": window.screen.width >= 767 ? "desktop" : "mobile"
        }),
        headers: {
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': APP_ID
        }
    }).then(response => response.json()).then((data) => data.result);
}

export function fetchPlayerHintImage() {
    return fetch(API_BASE_URL + '/functions/fetchPlayerHintImage', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': APP_ID
        }
    }).then(response => response.json()).then((data) => data.result);
}

export function fetchBanners() {
    return fetch(API_BASE_URL + '/functions/fetchBanners', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': APP_ID
        }
    }).then(response => response.json()).then((data) => data.result.data);
}