import React from 'react';
import { Link } from 'react-router-dom';
import storage from '../Utils/storage';
import utility from '../Utils/utility';
import { verifyPlayerIdForRedemption, fetchPlayerHintImage, redeemCoupon } from '../Utils/networkUtils';
import Modal from '../Modal/Modal';
import { useTranslation } from 'react-i18next';
import Confetti from 'react-confetti'

const RedeemCoupon = ({ history, location, match }) => {
    let { t } = useTranslation();
    return <RedeemCouponScreen trans={t} history={history} location={location} match={match} />
}

export default RedeemCoupon;

class RedeemCouponScreen extends React.Component {

    state = {
        product: {},
        playerId: '',
        couponCode: '',
        token: '',
        playerVerified: false,
        apiCall: false,
        apiCallErr: false,
        apiSuccess: false,
        playerName: '',
        modalTitle: 'Alert',
        modalBody: `<p className="ta-left">Default</p>`,
        modalCloseBtnText: 'Close',
        hintImageUrl: '',
        redeemSuccess: false
    }

    componentDidMount() {
        // let purchaseObj = storage.fetch('purchaseObj');
        // if (purchaseObj === null) {
        //   let redirectUrl = utility.getLinkURL("/");
        //   this.props.history.push(redirectUrl);
        // }

        // this.setState({
        //   product: storage.fetch('purchaseObj')
        // });

        // window.addEventListener("message", this.receivePostPaymentMessage, false);
    }

    receivePostPaymentMessage = (event) => {
        if (event.origin !== "https://secure.xsolla.com")
            return;

        if (event.data !== undefined && utility.isJson(event.data)) {
            var eventData = JSON.parse(event.data);
            if (eventData.data !== undefined && eventData.data.action === "close-widget") {
                if (storage.fetch('purchaseObjSuc') === true)
                    this.props.history.push(utility.getLinkURL("thank-you"));
            } else if (eventData.data !== undefined && eventData.data.action === "change-status") {
                if (eventData.data.value === "done") {
                    storage.store("purchaseObjSuc", true);
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.receivePostPaymentMessage, false);
    }

    verifyPlayerIdCall = async () => {
        await this.setState({ apiCall: true });
        let results = await verifyPlayerIdForRedemption(this.state.playerId, this.state.couponCode);
        if (results.status === 'SUCCESS') {
            this.setState({ token: results.data.token, playerName: results.playfabName });
            await this.successResponse();
        } else {
            await this.failureResponse(results.message);
        }
        await this.setState({ apiCall: false });
    }

    redeemCouponCall = async () => {
        await this.setState({ apiCall: true });
        let results = await redeemCoupon(this.state.playerId, this.state.couponCode);
        if (results.status === 'SUCCESS') {
            this.setState({ token: results.data.token, playerName: results.playfabName });
            await this.successResponse();
            await this.failureResponse("Coupon redeemed successfully!");
            this.setState({
                product: {},
                playerId: '',
                couponCode: '',
                token: '',
                playerVerified: false,
                playerName: '',
                redeemSuccess: true
            })
        } else {
            await this.failureResponse(results.message);
        }
        await this.setState({ apiCall: false });
    }

    failureResponse = async (errMessage) => {
        await this.setState({
            apiCallErr: true,
            modalBody: `<p class="ta-left">${errMessage}</p>`,
            apiSuccess: false,
            playerVerified: false
        })
    }

    successResponse = async () => {
        await this.setState({
            playerVerified: true,
            apiSuccess: true,
            apiCallErr: false
        });
    }

    fetchHintImage = async () => {
        let results = await fetchPlayerHintImage();
        if (results.status === 'SUCCESS') {
            let lang = storage.getCookieObj('lang');
            let imageUrl = (lang === "ar" ? results.data.playerIdHintImage_ar.url : results.data.playerIdHintImage.url);
            this.setState({
                hintImageUrl: imageUrl,
                modalTitle: this.props.trans('redeemCoupon.modalTitle1'),
                modalBody: `<img src=${imageUrl} width="400" alt="player id hint" />`,
                apiCallErr: true,
                modalCloseBtnText: this.props.trans('redeemCoupon.close')
            });
        } else {
            await this.failureResponse(results.message);
        }
    }

    handleModal = (close) => {
        if (close) {
            this.setState({
                apiCallErr: false,
                redeemSuccess: false
            })
        }
    }

    render() {
        let product = this.state.product;
        let lang = storage.getCookieObj('lang');
        return (
            <div id="all">
                <div id="content">
                    {
                        this.state.redeemSuccess &&
                        <Confetti
                            width={window.innerWidth}
                            height={window.innerHeight}
                        />
                    }
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={utility.getLinkURL("/")}>&lt; {this.props.trans('redeemCoupon.return')}</Link></li>
                                    </ol>
                                </nav>
                            </div>
                            <div id="checkout" className="col-lg-12">
                                <div className="box">
                                    <form>
                                        <div className="content">
                                            <div className="row">

                                                <div className="col-lg-6 mt50">
                                                    <h4 className="player-id-verify-header">{this.props.trans('redeemCoupon.verification')}</h4>

                                                    {
                                                        this.state.apiSuccess === true
                                                            ? (
                                                                <div className="row edit-box">
                                                                    <div className="col-md-6">
                                                                        <div className="input-group item">
                                                                            <label className="item-label">{this.props.trans('redeemCoupon.playerId')}</label>
                                                                            <label className="item-val">{this.state.playerId}</label>
                                                                        </div>
                                                                        <div className="input-group item">
                                                                            <label className="item-label">{this.props.trans('redeemCoupon.playerCouponCode')}</label>
                                                                            <label className="item-val">{this.state.couponCode}</label>
                                                                        </div>
                                                                        <div className="input-group item">
                                                                            <label className="item-label">{this.props.trans('redeemCoupon.playerName')}</label>
                                                                            <label className="item-val">{this.state.playerName}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <button type="button" className="btn btn-info edit-btn" onClick={() => this.setState({ apiSuccess: false, playerVerified: false })}>{this.props.trans('redeemCoupon.edit')}</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                            : (
                                                                <div className="container p0">
                                                                    <form>
                                                                        <div className="input-group">
                                                                            <input id="player-id" type="text" disabled={this.state.apiSuccess === true} className="form-control" name="playerId" value={this.state.playerId} onChange={(e) => this.setState({ playerId: e.target.value })} maxLength="20" placeholder={this.props.trans('redeemCoupon.placeholderPlayerId')} required={true} />
                                                                        </div>
                                                                        <div className="input-group hint-box">
                                                                            <i className="fa fa-question-circle"></i>&nbsp;
                                    <p htmlFor="player-id" onClick={this.fetchHintImage}>{this.props.trans('redeemCoupon.hintText')}</p>
                                                                        </div>
                                                                        <br />
                                                                        <div className="input-group">
                                                                            <input type="text" disabled={this.state.apiSuccess === true} className="form-control" name="couponCode" value={this.state.couponCode} onChange={(e) => this.setState({ couponCode: e.target.value })} maxLength="50" placeholder={this.props.trans('redeemCoupon.playerCouponCode')} />
                                                                        </div><br />
                                                                        <div className="input-group">
                                                                            <button disabled={this.state.apiCall === true || this.state.apiSuccess === true} type="button" className="btn btn-info" onClick={() => this.verifyPlayerIdCall()}>
                                                                                {this.props.trans('redeemCoupon.verify')}
                                                                                {this.state.apiCall === true && <i className="fa fa-spinner fa-spin"></i>}
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="box-footer d-flex justify-content-between">
                                            <button disabled={this.state.playerVerified === false} type="button" className="btn btn-primary" onClick={this.redeemCouponCall}>
                                                {this.props.trans('redeemCoupon.payNow')}
                                                {this.state.apiCall === true ? <i className="fa fa-spinner fa-spin"></i> : <i className="fa fa-chevron-right"></i>}
                                            </button>
                                        </div>
                                        <div className="box-footer d-flex" style={{ marginTop: 0 }}>
                                            <p>{this.props.trans('redeemCoupon.note')}</p>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                {
                    this.state.apiCallErr &&
                    <Modal
                        show={this.state.apiCallErr}
                        title={this.state.modalTitle}
                        body={this.state.modalBody}
                        handleModal={this.handleModal}
                        closeBtnText={this.state.modalCloseBtnText}
                    />
                }
            </div>
        );
    }
}