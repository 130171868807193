import React from 'react';
import { Link } from 'react-router-dom';
import storage from '../Utils/storage';
import { useTranslation } from 'react-i18next';
import utility from '../Utils/utility';

const ThankYou = ({ history }) => {
    let { t } = useTranslation();
    return <ThankYouScreen trans={t} history={history} />
}

export default ThankYou;

class ThankYouScreen extends React.Component {

    componentDidMount() {
        let purchaseObjSuc = storage.fetch('purchaseObjSuc');
        if (purchaseObjSuc === null) {
            let redirectUrl = utility.getLinkURL("/");
            this.props.history.push(redirectUrl);
        }
    }

    render() {
        return (
            <div id="all">
                <div id="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div id="error-page" className="row">
                                    <div className="col-md-6 mx-auto">
                                        <div className="box text-center py-5">
                                            <h3>{this.props.trans('thankYou.thankYou')}</h3>
                                            <p className="text-center">{this.props.trans('thankYou.message')}</p>
                                            <p className="buttons">
                                                <Link to={utility.getLinkURL("/")} className="btn btn-primary">
                                                    <i className="fa fa-home"></i> {this.props.trans('thankYou.goToHomePage')}
                                                </Link>
                                            </p>
                                            <p className="buttons">
                                                <a href="https://play.google.com/store/apps/details?id=com.aliha100.climbingsanddune3d1">
                                                    <img alt="android_link" src={utility.getAssetURL("/img/android_link.png")} width="200" />
                                                </a>
                                                <br /><br />
                                                <a href="https://apps.apple.com/us/app/csd-climbing-sand-dune/id886998934">
                                                    <img alt="ios_link" src={utility.getAssetURL("/img/ios_link.png")} width="200" />
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}