import React from 'react';
import { Link } from 'react-router-dom';
import storage from '../Utils/storage';
import utility from '../Utils/utility';
import { verifyPlayerId, fetchPlayerHintImage } from '../Utils/networkUtils';
import Modal from '../Modal/Modal';
import { useTranslation } from 'react-i18next';

const OrderDetail = ({ history, location, match }) => {
  let { t } = useTranslation();
  return <OrderDetailScreen trans={t} history={history} location={location} match={match} />
}

export default OrderDetail;

class OrderDetailScreen extends React.Component {

  state = {
    product: {},
    playerId: '',
    email: '',
    token: '',
    xsollaVerified: false,
    apiCall: false,
    apiCallErr: false,
    apiSuccess: false,
    playerName: '',
    modalTitle: 'Alert',
    modalBody: `<p className="ta-left">Default</p>`,
    modalCloseBtnText: 'Close',
    hintImageUrl: ''
  }

  componentDidMount() {
    let purchaseObj = storage.fetch('purchaseObj');
    if (purchaseObj === null) {
      let redirectUrl = utility.getLinkURL("/");
      this.props.history.push(redirectUrl);
    }

    this.setState({
      product: storage.fetch('purchaseObj')
    });

    window.addEventListener("message", this.receivePostPaymentMessage, false);
  }

  receivePostPaymentMessage = (event) => {
    if (event.origin !== "https://secure.xsolla.com")
      return;

    if (event.data !== undefined && utility.isJson(event.data)) {
      var eventData = JSON.parse(event.data);
      if (eventData.data !== undefined && eventData.data.action === "close-widget") {
        if (storage.fetch('purchaseObjSuc') === true)
          this.props.history.push(utility.getLinkURL("thank-you"));
      } else if (eventData.data !== undefined && eventData.data.action === "change-status") {
        if (eventData.data.value === "done") {
          storage.store("purchaseObjSuc", true);
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receivePostPaymentMessage, false);
  }

  verifyPlayerIdCall = async () => {
    await this.setState({ apiCall: true });
    let results = await verifyPlayerId(this.state.playerId, this.state.email, storage.fetch('purchaseObj').id);
    if (results.status === 'SUCCESS') {
      this.setState({ token: results.data.token, playerName: results.playfabName });

      var options = {
        access_token: results.data.token,
        sandbox: false,
        lightbox: {
          width: "100%",
          closeByClick: false,
          closeByKeyboard: false
        }
      };
      var s = document.createElement('script');
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://static.xsolla.com/embed/paystation/1.0.7/widget.min.js";
      s.addEventListener('load', function (e) {
        window.XPayStationWidget.init(options);
        window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.CLOSE, function (event, data) {
          window.location.reload();
        });
      }, false);
      var head = document.getElementsByTagName('head')[0];
      head.appendChild(s);

      await this.successResponse();
    } else {
      await this.failureResponse(results.message);
    }
    await this.setState({ apiCall: false });
  }

  failureResponse = async (errMessage) => {
    await this.setState({
      apiCallErr: true,
      modalBody: `<p class="ta-left">${errMessage}</p>`,
      apiSuccess: false,
      xsollaVerified: false
    })
  }

  successResponse = async () => {
    await this.setState({
      xsollaVerified: true,
      apiSuccess: true,
      apiCallErr: false
    });
  }

  fetchHintImage = async () => {
    let results = await fetchPlayerHintImage();
    if (results.status === 'SUCCESS') {
      let lang = storage.getCookieObj('lang');
      let imageUrl = (lang === "ar" ? results.data.playerIdHintImage_ar.url : results.data.playerIdHintImage.url);
      this.setState({
        hintImageUrl: imageUrl,
        modalTitle: this.props.trans('orderDetail.modalTitle1'),
        modalBody: `<img src=${imageUrl} width="400" alt="player id hint" />`,
        apiCallErr: true,
        modalCloseBtnText: this.props.trans('orderDetail.close')
      });
    } else {
      await this.failureResponse(results.message);
    }
  }

  handleModal = (close) => {
    if (close) {
      this.setState({
        apiCallErr: false
      })
    }
  }

  render() {
    let product = this.state.product;
    let lang = storage.getCookieObj('lang');
    return (
      <div id="all">
        <div id="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={utility.getLinkURL("products")}>&lt; {this.props.trans('orderDetail.return')}</Link></li>
                  </ol>
                </nav>
              </div>
              <div id="checkout" className="col-lg-12">
                <div className="box">
                  <form>
                    <div className="content">
                      {
                        Object.keys(product).length > 0
                          ? (
                            <div className="row product-description-row">
                              <div className="col-md-6">
                                <img width="200" src={product.image.url} alt="Product" />
                              </div>
                              <div className="col-md-6" style={{ textAlign: "-webkit-auto" }}>
                                <h3>{lang === "ar" ? product.name_ar : product.name}</h3>
                                <h3>{lang === "ar" ? product.amount_ar + " SAR" : product.amount + " USD"}</h3>
                                {
                                  lang === 'en'
                                    ? (
                                      product.discount !== undefined && product.discount !== "" && product.discount !== 0 &&
                                      <del>{parseFloat(product.amount) + parseFloat(product.discount)} USD</del>
                                    )
                                    : (
                                      (product.discount_ar !== undefined && product.discount_ar !== "" && product.discount_ar !== 0) &&
                                      <del>{parseFloat(product.amount_ar) + parseFloat(product.discount_ar)} SAR</del>
                                    )
                                }
                                <h4>{lang === "ar" ? product.description_ar : product.description}</h4>
                              </div>
                            </div>
                          )
                          : <h3>No product found</h3>
                      }

                      <div className="row">

                        <div className="col-lg-6 mt50">
                          <h4 className="player-id-verify-header">{this.props.trans('orderDetail.verification')}</h4>

                          {
                            this.state.apiSuccess === true
                              ? (
                                <div className="row edit-box">
                                  <div className="col-md-6">
                                    <div className="input-group item">
                                      <label className="item-label">{this.props.trans('orderDetail.playerId')}</label>
                                      <label className="item-val">{this.state.playerId}</label>
                                    </div>
                                    <div className="input-group item">
                                      <label className="item-label">{this.props.trans('orderDetail.playerEmail')}</label>
                                      <label className="item-val">{this.state.email}</label>
                                    </div>
                                    <div className="input-group item">
                                      <label className="item-label">{this.props.trans('orderDetail.playerName')}</label>
                                      <label className="item-val">{this.state.playerName}</label>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <button type="button" className="btn btn-info edit-btn" onClick={() => this.setState({ apiSuccess: false, xsollaVerified: false })}>{this.props.trans('orderDetail.edit')}</button>
                                  </div>
                                </div>
                              )
                              : (
                                <div className="container p0">
                                  <form>
                                    <div className="input-group">
                                      <input id="player-id" type="text" disabled={this.state.apiSuccess === true} className="form-control" name="playerId" value={this.state.playerId} onChange={(e) => this.setState({ playerId: e.target.value })} maxLength="20" placeholder={this.props.trans('orderDetail.placeholderPlayerId')} required={true} />
                                    </div>
                                    <div className="input-group hint-box">
                                      <i className="fa fa-question-circle"></i>&nbsp;
                                    <p htmlFor="player-id" onClick={this.fetchHintImage}>{this.props.trans('orderDetail.hintText')}</p>
                                    </div>
                                    <br />
                                    <div className="input-group">
                                      <input type="text" disabled={this.state.apiSuccess === true} className="form-control" name="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} maxLength="50" placeholder={this.props.trans('orderDetail.placeholderPlayerEmail')} />
                                    </div><br />
                                    <div className="input-group">
                                      <button disabled={this.state.apiCall === true || this.state.apiSuccess === true} type="button" className="btn btn-info" onClick={() => this.verifyPlayerIdCall()}>
                                        {this.props.trans('orderDetail.verify')}
                                        {this.state.apiCall === true && <i className="fa fa-spinner fa-spin"></i>}
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              )
                          }
                        </div>
                      </div>
                    </div>

                    <div className="box-footer d-flex justify-content-between">
                      <button disabled={this.state.xsollaVerified === false} type="button" className="btn btn-primary" data-xpaystation-widget-open>{this.props.trans('orderDetail.payNow')}<i className="fa fa-chevron-right"></i></button>
                    </div>
                    <div className="box-footer d-flex" style={{ marginTop: 0 }}>
                      <p>{this.props.trans('orderDetail.note')}</p>
                    </div>
                  </form>
                </div>

              </div>

            </div>
          </div>
        </div>
        {
          this.state.apiCallErr &&
          <Modal
            show={this.state.apiCallErr}
            title={this.state.modalTitle}
            body={this.state.modalBody}
            handleModal={this.handleModal}
            closeBtnText={this.state.modalCloseBtnText}
          />
        }
      </div>
    );
  }
}