import React from 'react';
import { Link } from 'react-router-dom';
import storage from '../../Utils/storage';
import utility from '../../Utils/utility';

const ProductItem = ({ propObj, name, name_ar, description, description_ar, discount, discount_ar, amount, amount_ar, image, annotations, annotations_ar, trans, onSelect, discountPercent, discountPercent_ar, route, onRedeem }) => {

    let lang = storage.getCookieObj('lang');

    return (
        <div className="product">

            {
                image &&
                <>
                    <div className="flip-container">
                        <div className="product-img">
                            <img src={image.url} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <Link to={utility.getLinkURL("order-detail")} className="invisible"><img src={image.url} alt="" className="img-fluid" /></Link>
                </>
            }
            <div className="text">
                <h3>
                    {lang === "ar" ? name_ar : name}
                </h3>
                {
                    amount &&
                    <p className="price">
                        {
                            (discount !== undefined && discount !== "" && parseFloat(discount) !== 0) &&
                            <del>{
                                lang === "ar"
                                    ? "SAR " + (parseFloat(amount_ar) + parseFloat(discount_ar)).toFixed(2)
                                    : "$ " + (parseFloat(amount) + parseFloat(discount)).toFixed(2)
                            }
                            </del>
                        }
                    &nbsp;
                    {
                            lang === "ar"
                                ? "SAR " + parseFloat(amount_ar).toFixed(2)
                                : "$ " + parseFloat(amount).toFixed(2)
                        }
                        <br />
                        {
                            description !== undefined && description !== ""
                                ? (lang === "ar" ? description_ar : description)
                                : <br />
                        }
                    </p>
                }
                <div className="box-footer mb10">
                    {
                        route === "game" && propObj.redeemFlag && 
                        <button className="btn btn-info w-100" onClick={() => onRedeem(propObj)}>
                            {trans('home.redeem')}
                            <i className="fa fa-chevron-right"></i>
                        </button>
                    }
                    {
                        (propObj.purchaseFlag === undefined ||  propObj.purchaseFlag === true) && 
                        <button className="btn btn-info mt-3 w-100" onClick={() => onSelect(propObj)}>
                            {trans('home.purchase')}
                            <i className="fa fa-chevron-right"></i>
                        </button>    
                    }
                </div>
            </div>

            {
                annotations !== "" && annotations !== undefined
                    ? <div className="ribbon sale" style={{ left: 0, paddingLeft: "77px" }}>
                        <div className="theribbon">{lang === "ar" ? annotations_ar : annotations}</div>
                        <div className="ribbon-background"></div>
                    </div>
                    : null
            }

            {
                discountPercent !== undefined && discountPercent !== "" && lang !== "ar"
                    ? <div className="ribbon gift" style={{ left: 0, paddingLeft: "77px" }}>
                        <div className="theribbon">Discount: {discountPercent}%</div>
                        <div className="ribbon-background"></div>
                    </div>
                    : null
            }

            {
                discountPercent_ar !== undefined && discountPercent_ar !== "" && lang === "ar"
                    ? <div className="ribbon gift" style={{ left: 0, paddingLeft: "77px" }}>
                        <div className="theribbon">{discountPercent_ar}</div>
                        <div className="ribbon-background"></div>
                    </div>
                    : null
            }

        </div>
    )
};

export default ProductItem;