import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import ar from '../Locales/default.ar.json';
import en from '../Locales/default.en.json';

const options = {
    interpolation: {
        escapeValue: false, // not needed for react!!
    },

    // debug: true,

    lng: 'ar',

    resources: {
        ar,
        en
    },

    fallbackLng: 'ar',

    ns: ['common'],

    defaultNS: 'common',

    react: {
        wait: true
    },
};

i18n
    .use(LanguageDetector)
    .init(options);

export default i18n;