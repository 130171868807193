const storageObject = localStorage

function store(key, val) {
    return storageObject.setItem(key, typeof val === "string" ? val : JSON.stringify(val));
}

function fetch(key) {
    return JSON.parse(storageObject.getItem(key));
}

function clear() {
    return storageObject.clear();
}

function storeCookieObj(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    window.document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookieObj(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default {
    store,
    fetch,
    clear,
    storeCookieObj,
    getCookieObj
}