import React from 'react';
import { fetchBanners, fetchGames } from '../Utils/networkUtils';
import ProductItem from '../Home/ProductItem/ProductItem';
import storage from '../Utils/storage';
import { useTranslation } from 'react-i18next';
import utility from '../Utils/utility';

const GameList = ({ history, location, match }) => {
    let { t, i18n } = useTranslation();
    return <GameListScreen trans={t} i18n={i18n} history={history} location={location} match={match} />
}

export default GameList;

class GameListScreen extends React.Component {

    state = {
        games: [],
        banners: []
    }

    async componentDidMount() {
        storage.clear();
        await this.fetchBannersCall();
        await this.fetchGamesCall();

        let script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.src = utility.getAssetURL("/js/front.js");
        document.head.appendChild(script1);

        let script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.src = utility.getAssetURL("/js/custom.js");
        document.head.appendChild(script2);
    }

    fetchGamesCall = async () => {
        let games = await fetchGames();
        this.setState({ games });
    }

    fetchBannersCall = async () => {
        let banners = await fetchBanners();
        this.setState({ banners });
    }

    selectGame = (game) => {
        storage.store('gameObj', game);
        this.props.history.push(utility.getLinkURL("products"));
    }

    onRedeem = (game) => {
        storage.store('gameObj', game);
        this.props.history.push(utility.getLinkURL("redeem/csd"));
    }

    render() {
        let lang = storage.getCookieObj('lang');
        let bannerKey = (lang === "ar") ? 'banner_ar' : 'banner';
        return (
            <div>
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {
                            this.state.banners.length > 0
                            && this.state.banners.map((banner, key) => {
                                if (banner[bannerKey] !== undefined)
                                    return <div className="swiper-slide" key={key}>
                                        <img src={banner[bannerKey]['url']} alt="" className="img-fluid" />
                                    </div>
                                return null;
                            })
                        }
                    </div>

                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                </div>

                <div id="all">
                    <div id="content">
                        <div id="hot">
                            <div className="container">
                                <div className="product-slider owl-carousel owl-theme">
                                    {
                                        this.state.games.length > 0
                                            ? this.state.games.map((game, key) => {
                                                return <div className="item" key={key}>
                                                    <ProductItem {...game} {...this.props} onSelect={this.selectGame} propObj={game} route="game" onRedeem={this.onRedeem} />
                                                </div>
                                            })
                                            : <div className="col-lg-12">
                                                <h4 className="mb-3 ta-left col-ffffff">No Games found</h4>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}