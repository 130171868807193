import storage from "./storage";

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function getAssetURL(assetURL) {
    return process.env.PUBLIC_URL + assetURL;
}

function getLinkURL(url) {
    url = url !== "/" ? url : "";
    return process.env.PUBLIC_URL + "/" + storage.getCookieObj('lang') + "/" + url;
}

function getLanguages() {
    return ['ar', 'en'];
}

function setGlobalLanguageParams(i18n, pathname) {
    pathname = pathname.substring(0, 4);
    pathname = pathname.replace(/\//g, "");
    if (pathname === "en") {
        storage.storeCookieObj('dir', "ltr", 30);
        storage.storeCookieObj('lang', "en", 30);
    } else {
        storage.storeCookieObj('dir', "rtl", 30);
        storage.storeCookieObj('lang', "ar", 30);
    }
    i18n.changeLanguage(pathname);
}

export default {
    isJson,
    getAssetURL,
    getLinkURL,
    getLanguages,
    setGlobalLanguageParams
}